import { connect } from 'react-redux';
import { encodePassengers } from 'utils/Reserbus';
import PriceChangedModal from './PriceChangedModal';

const mapStateToProps = ({ purchase }) => {
  const passengersSelection = purchase.get('passengersSelection');
  const encodedPassengersSelection =
    (passengersSelection && encodePassengers(passengersSelection)) || 'A1';
  const departureTaxes = purchase.getIn(['departs', 'pricing', 'breakdown'])?.toJS() || null;
  const returnTaxes = purchase.getIn(['returns', 'pricing', 'breakdown'])?.toJS() || null;
  const departureTaxesTotal = departureTaxes
    ? Object.values(departureTaxes).reduce((acc, tax) => acc + tax, 0)
    : 0;
  const returnTaxesTotal = returnTaxes
    ? Object.values(returnTaxes).reduce((acc, tax) => acc + tax, 0)
    : 0;
  const totalTaxes = Math.round(departureTaxesTotal + returnTaxesTotal, 2);
  return {
    total: purchase.get('total'),
    departure: purchase.getIn(['departs', 'departure']),
    origin: purchase.getIn(['departs', 'origin', 'cityName']),
    originId: purchase.getIn(['departs', 'origin', 'id']),
    destination: purchase.getIn(['departs', 'destination', 'cityName']),
    destinationId: purchase.getIn(['departs', 'destination', 'id']),
    totalBeforeLock: purchase.get('totalBeforeLock'),
    hasDiscount: Boolean(purchase.get('discountAmount')),
    walletBalanceUsed: purchase.get('walletBalanceUsed') || 0,
    encodedPassengersSelection,
    totalTaxes,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapStateToProps, null, mergeProps)(PriceChangedModal);
